<template>
  <div class="content">
    <div class="line-left">
      <a-dropdown>
        <a class="ant-dropdown-link line-course" @click.prevent>
          当前科目:<span class="line-course-name">
            {{ nowCourseName }}
          </span>
          <DownOutlined />
        </a>
        <template #overlay>
          <a-menu @click="changeCourse">
            <a-menu-item v-for="item in courseNames" :key="item.key">{{
              item.name
            }}</a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <div id="main"></div>
      <span class="chart-title">各科-各年级 课件数</span>
    </div>
    <div class="bar-right">
      <div id="secondMain"></div>
      <span class="chart-title">各年级-各科 课件数</span>
    </div>
  </div>
</template>

<script>
import { getChartsData } from "@/api/home";

import { DownOutlined } from "@ant-design/icons-vue";

import * as echarts from "echarts/core";
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { BarChart, LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
]);

export default {
  components: {
    DownOutlined,
  },
  data() {
    return {
      courseItems: [], //数据列表
      courseNames: [], //所有科目名称列表{name,key}
      nowCourseName: "", //当前选择的科目
      myChart: Object,
      myBarChart: Object,//堆叠
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          data: ["上学期", "下学期", "年级总数"],
        },
        xAxis: [
          {
            type: "category",
            data: ["1年级", "2年级", "3年级", "4年级", "5年级", "6年级"],
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "课件数量",
            min: 0,
            alignTicks: true,
            axisLabel: {
              formatter: "{value} 个",
            },
          },
          {
            type: "value",
            name: "年级总数",
            min: 0,
            alignTicks: true,
            axisLabel: {
              formatter: "{value} 个",
            },
          },
        ],
        series: [
          {
            name: "上学期",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + " 个";
              },
            },
            data: [],
          },
          {
            name: "下学期",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + " 个";
              },
            },
            data: [],
          },
          {
            name: "年级总数",
            type: "line",
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + " 个";
              },
            },
            data: [],
          },
        ],
      },
      barOption: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: ["1年级", "2年级", "3年级", "4年级", "5年级", "6年级"],
        },
        series: [],
      }
    };
  },
  mounted() {
    var chartDom = document.getElementById("main");
    this.myChart = echarts.init(chartDom);
    var barChartDom = document.getElementById("secondMain");
    this.myBarChart = echarts.init(barChartDom);
    this.requestAllChants();
  },
  methods: {
    requestAllChants() {
      getChartsData().then((res) => {
        console.log(res);
        this.courseItems = res;
        var barOptions = [];
        var barSeries = [];
        res.forEach((item, index) => {
          this.courseNames.push({ name: item.courseName, key: `${index}` });
          var bar = {
            name: item.courseName,
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: [],
          };
          item.gradeList.forEach(grade => {
            //年级列表
            var gradeNum = 0;
            grade.semesterList.forEach(semester => {
              //学期
              gradeNum += semester.count;
            })
            bar.data.push(gradeNum);
          })
          barSeries.push(bar);
        });
        this.barOption.series = barSeries;
        this.refrshChart(res[0]);
        //堆叠条形图
        this.refrshStackChart();
      });
    },
    //切换课程
    changeCourse({ key }) {
      this.refrshChart(this.courseItems[parseInt(key)]);
    },
    //刷新chart
    refrshChart(course) {
      this.nowCourseName = course.courseName;
      var lastNums = [];
      var nextNums = [];
      var totalNums = [];
      course.gradeList.forEach((item) => {
        //年级列表
        var total = 0;
        item.semesterList.forEach((obj) => {
          if (obj.semester == "上学期") {
            lastNums.push(obj.count);
          } else {
            nextNums.push(obj.count);
          }
          total += obj.count;
        });
        totalNums.push(total);
      });
      this.option.series[0].data = lastNums;
      this.option.series[1].data = nextNums;
      this.option.series[2].data = totalNums;
      this.option && this.myChart.setOption(this.option);
    },
    //刷新second
    refrshStackChart() {
      this.barOption && this.myBarChart.setOption(this.barOption);
    },
  },
};
</script>

<style lang="less" scoped>
.content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20PX;
}

.line-left {
  // width: 50%;
  flex:0.49;
  height: 90vmin;
  background-color: #fff;
  border-radius: 20px;
  padding-top: 30px;
  position: relative;

  #main {
    margin-top: 20px;
    width: 100%;
    height: 90%;
  }

  .line-course {
    margin: 30px;
    font-size: 20px;
    font-weight: 700;
    color: #000;

    .line-course-name {
      color: #6e76ff;
    }
  }
}

.bar-right{
  flex:0.49;
  height: 90vmin;
  background-color: #fff;
  border-radius: 20px;
  position: relative;

  #secondMain {
    margin-top: 20px;
    width: 100%;
    height: 90%;
  }
}

.chart-title{
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

</style>